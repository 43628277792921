.space-between {
    display: flex;
    justify-content: space-between;

    .-nextButton,
    .-middleButton {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .-middleButton {
        justify-content: center;

        @include media-down(m) {
            order: -1;
            align-items: center;
        }
    }
}

.space-evenly {
    display: flex;
    justify-content: space-evenly;
}

.min-content-width {
    min-width: min-content;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.aligned-inherit {
    align-items: inherit;
}

.aligned-center {
    display: flex !important;
    align-items: center !important;

    .-buttonText {
        margin-left: 15px;
    }
}

.aligned-baseline {
    display: flex;
    align-items: baseline !important;
}

.aligned-start {
    display: flex;
    align-items: flex-start !important;

    .m-radioGroupContainer__options {
        justify-content: flex-start;
    }
}

.aligned-end {
    display: flex;
    align-items: end !important;
}

.aligned-left {
    display: flex;
    justify-content: flex-start;

    .a-btn {
        width: unset !important;
    }
}

.aligned-right {
    display: flex;
    justify-content: flex-end;

    .a-btn {
        width: unset !important;
    }
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-no-wrap {
    flex-wrap: nowrap;
}

.justify-start {
    display: flex;
    justify-content: flex-start;
}

.justify-end {
    display: flex;
    justify-content: flex-end;
}

.help-icon {
    position: relative;
    display: flex;
    height: max-content;
    margin-left: 10px;

    &.-modal {
        @include media-down(l) {
            .a-tooltipInfo {
                &::before {
                    left: 60px;
                }
            }
        }
    }
}

.help-icon-checkbox {
    position: relative;
    margin-left: 10px;

    &.-modal {
        @include media-down(l) {
            .a-tooltipInfo {
                &::before {
                    left: 60px;
                }
            }
            .a-iconShowcase {
                &::before {
                    left: 60px;
                }
            }
        }
    }
}

.centered-checkbox {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-start;

    &.-withSelect {
        align-items: flex-start !important;

        .a-checkbox,
        .help-icon {
            margin-top: 40px;

            @include media-down(s) {
                margin-top: 0;
            }
        }
    }

    &.-topLevel {
        z-index: 5;
    }

    .a-checkboxWithTitle {
        margin-top: 10px;
        display: flex;
        align-items: flex-start;

        @include media-down(l) {
            margin-top: 10px;
        }
    }

    .help-icon {
        margin-top: 10px;
    }
}

.justify-center {
    justify-content: center;
    display: flex;
    align-items: flex-start;

    & > div {
        width: 100%;
    }
}

.redStar {
    color: $red;
}

.placeholderCard {
    width: 100%;

    @include media-down(m) {
        width: 100%;

        &.-second {
            display: none;
        }
    }

    .-image {
        height: 225px;
        background-image: url(../../../images/noData.svg);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.cardPlaceholder {
    padding: 0;

    .placeholderCard .-image {
        height: 250px;
    }
}

.fakeLabel {
    label {
        opacity: 0;
        visibility: hidden;
    }

    &.-notFirst {
        .m-selectGroup {
            label:first-of-type {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.calendarMovedToRight {
    .flatpickr-calendar.static {
        right: 0 !important;

        @include media-down(m) {
            right: unset !important;
        }
    }
}

.mobile {
    @include media-up(m) {
        display: none;
    }
}

.largeScreens {
    @include media-up(l) {
        display: none;
    }
}

@include media-down(l) {
    .first-field {
        padding-right: 10px;

        &.noPadding {
            padding-right: 0;
        }
    }

    .second-field {
        padding-left: 10px;
    }
}

.removeItemIcon {
    position: relative;
    display: flex;
    width: 10px;
    height: 10px;
    cursor: pointer;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        width: 100%;
        height: 1px;
        border-radius: 1px;
        background: $dark-blue-60;
    }

    &::before {
        transform: translateY(-50%) rotate(-45deg);
    }

    &::after {
        transform: translateY(-50%) rotate(45deg);
    }

    &.-large {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        max-width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        margin-left: 25px;

        &::before,
        &::after {
            height: 1.5px;
            background-color: $dark-blue;
        }
    }

    &.-invite {
        flex: 0 0 10%;

        @include media-down(l) {
            z-index: 2;
            position: absolute;
            top: 16px;
            right: 17px;
        }
    }
}

.radioButtonLeftAligned {
    .m-radioGroupContainer__options {
        flex-direction: column;
        align-items: flex-start;
    }
}

.nightTimeDive {
    .errorMessage {
        margin-top: 25px;
    }
}

.file-upload .m-addAndUpload {
    height: 100%;
}

.canCreateRecordsButton {
    .a-btn.-secondary {
        margin-left: 20px;
    }

    @include media-down(xs) {
        display: flex;
        flex-direction: column;

        & > div {
            width: 100%;
        }

        .a-btn {
            width: 100% !important;

            &.-secondary {
                margin-left: 0;
            }
        }
    }
}

.space-between-buttons {
    display: flex;

    .a-btn:first-of-type {
        margin-right: 20px;
    }

    @include media-down(s) {
        .a-btn.-primary {
            width: 100%;
        }
    }
}

.fakeInput {
    display: none;
}

fieldset:disabled {
    label,
    input {
        opacity: 0.7;
    }
}

.profileWrapper {
    padding: 0;
}

.confirmAndReject {
    .-reject {
        margin-right: 20px !important;
    }

    .-nextButton {
        .a-btn.-primary {
            width: 50%;
        }

        .-reject {
            width: 50%;
            margin-right: 20px;

            .a-btn {
                width: 100%;
            }
        }
    }

    @include media-down(m) {
        .space-between {
            display: flex;
            flex-direction: column;

            .-backButton {
                width: 100%;

                .a-btn,
                & > div {
                    margin-top: 10px;
                    width: 100%;
                }
            }

            .-nextButton {
                display: flex;
                width: 100%;
                margin-top: 20px;
            }
        }
    }
}

//MINA TO DO: Remove this to separate scss file because it is related only to Personal info modal
.personalInfoModal {
    .m-radioGroupContainer__options {
        justify-content: flex-start;
    }

    .m-step__buttons {
        @include media-up(m) {
            .-backButton,
            .-nextButton {
                width: 50%;

                .a-btn {
                    width: 100%;
                }
            }

            .-backButton {
                margin-right: 10px;
            }

            .-nextButton {
                margin-left: 10px;
            }
        }

        @include media-down(m) {
            .-backButton,
            .-nextButton {
                width: 45%;

                .a-btn span {
                    overflow: visible;
                    text-overflow: none;
                }
            }
        }

        @include media-down(s) {
            flex-direction: column;

            .-backButton,
            .-nextButton {
                width: 100%;

                .a-btn {
                    width: 100%;
                }
            }

            .-nextButton {
                order: -1;
                margin-bottom: 20px;
            }
        }
    }

    @include media-down(m) {
        .-contentElements {
            padding-bottom: 50px;

            &.-individualsManagement {
                padding-bottom: 0;
            }
        }
    }

    .m-userMainInfo__mainInfo--image,
    .m-userMainInfo__mainInfo--placeholder {
        width: 150px;
        min-width: 150px;
        padding-top: 150px;

        @include media-down(m) {
            margin-bottom: 20px;
        }
    }

    @include media-down(m) {
        .-mainInfo {
            margin-top: 20px;
            padding: 15px 10px 0;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 20px;
                width: calc(100% - 40px);
                height: 1px;
                border-top: 1px dashed $dark-blue-20;
                padding: 0 10px;
            }
        }
    }

    .-companyManagement,
    .-individualsManagement {
        @include media-down(xl) {
            .-mainInfo {
                margin-top: 20px;
                padding: 15px 10px 0;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 20px;
                    width: calc(100% - 40px);
                    height: 1px;
                    border-top: 1px dashed $dark-blue-20;
                    padding: 0 10px;
                }
            }
        }
    }
}

//MINA TO DO: Remove this to separate scss file because it is related only to call center
.-callCenterView {
    .chat-item {
        background-color: $silver;
        border-radius: 10px;
        margin-bottom: 20px;
        padding: 10px;
        display: flex;
        &:nth-child(odd) {
            margin-right: auto;
        }
        &:nth-child(even) {
            margin-left: auto;
            background-color: $light-blue-05;
        }
    }
}
//MINA TO DO: Remove this to separate scss file because it is related only to call center
.-callCenterTable {
    .-statistics-wrapper {
        display: flex;
        flex-direction: row;
        gap: 20px;
        .statistics-item {
            flex: 1;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            padding: 10px 0;
            margin-bottom: 20px;
            background-color: $silver;
            p {
                text-align: center;
            }
        }
    }

    @include media-down(xl) {
        .-statistics-wrapper {
            .statistics-item {
                flex: 0 0 calc(50% - 10px); /* Each item takes half the width with spacing */
            }
        }
    }

    @include media-down(s) {
        .-statistics-wrapper {
            .statistics-item {
                flex: 0 0 100%; /* Each item takes half the width with spacing */
            }
        }
    }
}

.multipleInputs {
    label {
        display: none;
    }

    .m-inputGroup {
        &:first-of-type {
            margin-right: 20px;
        }

        &__units {
            top: 20px !important;
        }
    }
}

.fullWidth {
    width: 100%;
}

.fullHeight {
    height: 100%;
}

.centered-text {
    text-align: center;
}

.fullWidthButton {
    .a-btn {
        width: 100%;
    }

    @include media-down(l) {
        &.-left {
            padding-right: 10px;
        }

        &.-right {
            padding-left: 10px;
        }
    }
}

.diverInformation {
    .m-tab__tab {
        font-size: 14px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.verifiedIcon {
    min-width: 20px;
    width: 20px;
    height: 100%;
    margin-left: 5px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.coverImage {
    &__cropper {
        width: 100%;
        height: 357px;
        position: relative;
    }

    &__bottomContainer {
        padding: 20px 30px 0px 30px;
        flex-wrap: wrap;
        row-gap: 20px;

        &.-zoomContainer {
            h5 {
                font-size: 16px;
                margin-bottom: 10px;
            }
        }
    }

    &__rightButtonContainer {
        display: flex;
        justify-content: space-between;
        column-gap: 20px;
        flex-wrap: wrap;
    }
}

.listOfStatuses {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;

    .a-status {
        white-space: normal;
    }

    &.-withTooltip {
        position: relative;

        .-tooltip {
            z-index: 10;
            right: 0;
            transform: translate(0, 0);
        }

        .a-status:last-of-type {
            padding: 0;
            border: none;
            color: $dark-blue-60;
            cursor: pointer;

            &:hover + .-tooltip {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

.btnLeftAlign {
    .a-btn > div {
        justify-content: flex-start !important;
    }
}

.googleMaps {
    margin: 0;
    padding: 0;
    border-radius: 10px;
    overflow: hidden;

    & > div {
        max-height: 350px;
    }
}

.fullHeightBoxes {
    @include media-up(xl) {
        min-height: calc(100vh - 120px) !important;
    }
}

.z-index {
    position: relative;
    z-index: 1;
}

.qrCodeBox {
    display: flex;
    justify-content: center;
}

.list {
    margin-left: 20px;

    li {
        list-style: disc;
    }
}

.clearPadding {
    padding: 0 !important;
}

.content-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.subcriteria-list {
    list-style-type: disc;
    margin-left: 20px;
}

.ellipsis-text {
    width: 100%;
    display: block !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ellipsis-text-two-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ellipsis-text-three-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.modal-upload-note {
    font-size: 14px;
    font-style: italic;
    margin-top: 5px;
    padding: 0 10px;
}

.opacity80 {
    opacity: 0.8;
}

.separatorDisc {
    margin: 0 5px;
}

.spaceBetweenTwoButtons {
    display: flex;
    justify-content: space-between;

    .-backButton,
    .-nextButton {
        width: 50%;

        .a-btn {
            width: 100%;
        }
    }

    @include media-down(s) {
        flex-direction: column;
        gap: 20px;

        .-backButton,
        .-nextButton {
            width: 100%;

            .a-btn {
                width: 100%;
            }
        }

        .-nextButton {
            order: -1;
        }
    }
}

.pointer {
    cursor: pointer;
}

.pointerDefault {
    cursor: default !important;
}

.breakWord {
    word-break: break-word;
}

.border {
    border: 1px solid $dark-blue-20;

    &.-lightBlueBorder {
        border-color: $light-blue;
    }
}

.withTransition {
    transition: 0.3s ease-in-out;
}

.relative {
    position: relative;
}
