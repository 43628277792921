.-verificationModal {
    .verificationLink {
        display: flex;
        gap: 20px;

        @include media-down(xs) {
            flex-wrap: wrap;
            justify-content: center;
            gap: 5px;
        }

        .m-inputGroup {
            width: 100%;
            margin-bottom: 0;
        }
    }

    .a-btn.-tertiary {
        width: unset;
    }
}
