.m-noAuth {
    display: flex;
    background: linear-gradient(180deg, $light-blue -91.44%, $dark-blue 44.26%);

    @include media-down(l) {
        flex-direction: column;
    }

    &__form {
        position: relative;
        width: 50vw;
        min-width: 50vw;
        height: 100vh;
        max-height: 100vh;
        background-color: $white;
        padding: 50px 30px;
        overflow-y: auto;

        @include scrollbar;

        .-container {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 100%;
            padding-top: 40px;
        }

        .-descriptionText {
            text-align: center;

            @include media-up(l) {
                max-width: 400px;
            }
        }

        .-button {
            position: absolute;
            top: 30px;
            left: 50%;
            width: 100%;
            padding-left: 20px;
            transform: translateX(-50%);

            @include media-up(xs) {
                max-width: 400px;
            }

            .a-btn {
                div {
                    justify-content: flex-start;
                }
            }

            @include media-down(m) {
                top: 10px;
            }
        }

        .-backBtn {
            position: absolute;
            top: 0;
            left: 50px;

            @include media-down(l) {
                left: 0;
            }
        }

        .-confirmButton {
            width: 100%;

            @include media-up(xs) {
                max-width: 400px;
            }

            .a-btn {
                div {
                    justify-content: center;
                }
            }

            @include media-down(m) {
                top: 10px;
            }
        }

        @include media-down(l) {
            position: relative;
            width: 100%;
            height: calc(100vh - 200px);
            border-radius: 20px 20px 0px 0px;
        }

        .m-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            form {
                width: 100%;

                @include media-up(m) {
                    max-width: 400px;
                }

                &.-diverPhoto {
                    @include media-up(m) {
                        max-width: 450px;
                    }
                }
            }

            &__link {
                text-align: center;
            }

            &__text {
                text-align: center;
                display: flex;
                flex-direction: row;
            }

            .m-selectGroup {
                &.-multiple {
                    margin-bottom: 20px;
                }

                &__field {
                    display: flex;
                    align-items: baseline;
                    flex-wrap: wrap;
                    width: 100%;

                    & > div {
                        &:first-of-type {
                            width: 100%;
                        }

                        &:last-of-type {
                            width: calc(70% - 10px);
                        }
                    }

                    .a-btn {
                        margin-left: 10px;
                        width: 30%;
                    }
                }
            }

            .a-progressBar {
                @include media-up(m) {
                    max-width: 400px;
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            &.-selectRolesScreen {
                .-title {
                    max-width: 360px;
                }

                form {
                    max-width: 560px;
                }

                @include media-down(s) {
                    .a-tooltipInfo {
                        &::before {
                            top: calc(100% + 15px);
                            left: unset;
                            right: 0;
                            transform: translate(10px, 0);
                        }

                        &::after {
                            transform: rotate(90deg);
                            top: calc(100% - 5px);
                            left: 0;
                        }
                    }
                }

                .-mainErrorMessage {
                    margin-left: 70px;
                }

                .-roleNextBtn {
                    .a-btn {
                        max-width: unset;
                        margin-left: 70px;
                    }
                }
            }
        }

        h1 {
            text-align: center;
        }

        &--legal {
            margin-top: 30px;

            p {
                text-align: center;
            }
        }
    }

    &__formField {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .-max-Width400 {
            @include media-up(m) {
                max-width: 400px;
            }
        }

        .-max-Width510 {
            width: 100%;

            @include media-up(l) {
                max-width: 510px;
            }

            form {
                @include media-up(m) {
                    max-width: unset;
                }

                @include media-up(l) {
                    max-width: 510px;
                }
            }
        }

        .-signUpAs {
            .a-tooltipInfo {
                &::before,
                &::after {
                    left: 50%;
                }

                &::before {
                    max-width: 200px;
                }
            }
        }

        .-comingSoon {
            z-index: 2;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            padding: 3px 10px;
            white-space: nowrap;
        }
    }

    &__logo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 40px;
        margin-left: 10px;
    }

    &__image {
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50vw;
        height: 100vh;
        background: url(../../../images/noAuth.png);
        background-size: cover;
        background-position: bottom center;
        background-repeat: no-repeat;
        padding: 130px 100px;

        @include media-down(l) {
            position: relative;
            display: flex;
            justify-content: center;
            width: 100%;
            height: 200px;
            background: transparent;
            padding: 0;

            &--title {
                h1,
                .-welcome {
                    display: none;
                }

                .-description {
                    max-width: 230px;
                    text-align: center;
                }
            }
        }

        &--title {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            color: $white;

            @include media-down(xl) {
                width: 100%;
            }

            @include media-down(l) {
                align-items: center;
                justify-content: center;
            }

            h1 {
                margin-top: 20px;
                color: $white;
                font-size: 50px;
                font-weight: 500;

                @include media-up(l) {
                    max-width: 525px;
                }

                @include media-down(xl) {
                    font-size: 36px;
                }
            }

            .-welcome {
                text-transform: uppercase;
                margin-top: 50px;
            }
        }

        &--icon {
            width: 150px;
            height: 150px;
            background-image: url(../../../images/logo.svg);
            background-size: contain;
            background-repeat: no-repeat;

            @include media-down(l) {
                height: 45px;
                background-position: center;
                background-image: url(../../../images/logo-mobile.svg);
            }
        }
    }

    .m-inputGroup {
        &.-icon input {
            padding-right: 55px;
        }

        &.-label {
            svg {
                top: 53px !important;
            }
        }

        svg {
            overflow: visible;
            margin-right: 0;
        }
    }

    .a-socialButtons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;

        .-singleButton {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            border: 1px solid $dark-blue-20;
            background-color: $white;
            padding: 10px;
            width: 45px;
            height: 45px;
            min-width: 45px;
            min-height: 45px;
            cursor: pointer;
            transition: 0.3s ease-in-out;

            &:hover {
                background-color: $dark-blue-05;
            }

            & > div {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.max-width {
    max-width: 400px;
    margin: 0 auto;

    @include media-down(m) {
        max-width: 100%;
    }

    .a-progressBar {
        max-width: unset;
        padding-left: 0;
        padding-right: 0;
    }
}
