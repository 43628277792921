.m-modal {
    opacity: 0;
    visibility: hidden;
    z-index: 20;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    width: 100vw;
    height: 100%;
    background-color: $dark-blue-50;
    pointer-events: none;
    overflow-x: hidden;
    overflow-y: auto;
    transition: 0.3s ease-in-out;
    @include scrollbar;

    &.-show {
        opacity: 1;
        visibility: visible;
        pointer-events: initial;
    }

    .wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        border-radius: 10px;
        max-width: 80vw;
        width: 1030px;

        @include media-up(xl) {
            max-width: 1030px;
        }

        @include media-down(l) {
            padding-top: 0;
            height: 100%;
            max-width: 100vw !important;
            width: 100vw !important;
            border-radius: 0;
        }
    }

    .-modalElements {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: space-between;
    }

    &.-smallModal {
        .wrapper {
            width: 500px;
        }

        .-header {
            min-height: auto;
            border-bottom: none;
            padding: 30px 40px 20px;
        }

        .-footer {
            min-height: auto;
            border-top: none;
            padding: 20px 30px 30px;
        }

        .-bodyContent {
            padding: 0 30px;

            @include media-down(l) {
                padding: 20px;
            }
        }

        .-body {
            height: auto;
        }

        .m-modal__content {
            max-height: none;
        }

        //Note: This class only exist for z index
        &.-confirmationModal {
            z-index: 22;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        max-height: calc(100vh - 100px);

        @include media-down(l) {
            max-height: 100vh;
        }

        .fullWidth {
            position: relative;
        }

        ._wr {
            padding-left: 0;
            padding-right: 0;

            ._w {
                margin-left: 0;
                margin-right: 0;
            }

            @include media-down(l) {
                max-width: unset;
                height: 100%;

                ._w {
                    margin-left: 0;
                }
            }
        }

        .-insideContent {
            width: 100%;
            border-radius: 10px;
            box-shadow:
                0 4px 8px 0 rgba($dark-blue, 0.2),
                0 6px 20px 0 rgba($dark-blue, 0.19);
            background-color: $white;
            max-height: calc(100vh - 100px);

            @include media-down(l) {
                height: 100%;
                max-height: unset;
                min-height: 100%;
                border-radius: 0;
            }
        }

        scroll-padding-right: 10px !important;

        &::-webkit-scrollbar-track-piece:end {
            margin-bottom: 155px;
        }

        &::-webkit-scrollbar-track-piece:start {
            margin-top: -80px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .m-addAndUpload {
        height: 100%;
    }

    // Note: This is for image preview modal
    &.-imagePreviewModal {
        z-index: 21;

        .-imagePreview {
            max-width: 100%;
            max-height: 620px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            img {
                border: 1px solid $dark-blue-05;
                border-radius: 10px;
                object-fit: contain;
                height: 100%;
            }
        }
    }

    // Note: This is for PDF preview modal
    &.-pdfModal {
        .react-pdf__message.react-pdf__message--error {
            color: $dark-blue-60;
            font-size: 12px;
            font-weight: 400;
        }

        .react-pdf__Document {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 620px;

            .react-pdf__Page {
                max-width: 480px;
                min-width: unset !important;
                max-height: 680px;
                border-radius: 10px;
                border: 1px solid #d9d9d9;
                overflow: hidden;

                canvas {
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }

        .-pageControls {
            z-index: 2;
            position: absolute;
            transform: translateY(calc(-100% - 10px));
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            & > div {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 12px 20px;
                border-radius: 4px;
                box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
                background-color: $white;

                .a-btn {
                    margin-top: 0;
                    padding: 0;
                    height: 16px !important;
                }

                span {
                    margin: 0 15px;
                    white-space: nowrap;
                }
            }
        }
    }

    .-divingModes {
        width: 100%;

        .-fieldsError {
            margin-top: -10px;
            display: none;
        }

        &:has(.m-inputGroup .errorMessage.-active) {
            .m-inputGroup .errorMessage {
                display: none;
            }

            .a-input {
                border: 1px solid $red;
            }

            .-fieldsError {
                display: block;
            }
        }
    }

    // Mina TO DO: Came up with the idea how can this be separated from modal file
    &.experience-modal {
        .-totalDivesNumberActive .-divingModes:not(.-totalNumbersOfDives),
        .-totalDivesNumberDisabled .-totalNumbersOfDives {
            input {
                background-color: $dark-blue-05;
                border: 1px solid $dark-blue-05;
            }
        }
    }

    .-header,
    .-footer {
        background: $white;
        width: 100%;
    }

    .-header {
        min-height: 64px;
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid $dark-blue-20;
        padding: 20px 40px;

        .a-iconBtn {
            height: max-content;
        }

        @include media-down(l) {
            border-radius: 0;
            padding: 20px;
        }
    }

    .-footer {
        min-height: 80px;
        border-radius: 0 0 10px 10px;
        border-top: 1px solid $dark-blue-20;
        padding: 20px 30px;

        @include media-down(l) {
            padding: 20px 0;
        }

        .-prevButton,
        .-middleButton,
        .-nextButton {
            display: flex;
            align-items: center;
            justify-content: center;

            .a-btn {
                width: 100%;
            }
        }

        @include media-down(l) {
            border-radius: 0;
        }

        @include media-down(m) {
            .-prevButton {
                margin-top: 20px;
            }

            .-nextButton {
                order: -1;
            }

            .-middleButton {
                margin-bottom: 10px;
            }
        }
    }

    .-body {
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: $white;

        .m-inputGroup.-icon svg {
            top: 50px;
        }

        @include scrollbar;

        @include media-down(l) {
            height: 100vh;
            max-height: calc(100vh - 70px);
            margin-left: 0;
        }
    }

    .-bodyContent {
        width: 100%;
        height: 100%;
        margin-left: 0;
        padding: 30px;
        background-color: $white;

        @include media-down(l) {
            padding: 20px 0;
            height: auto;
        }
    }

    &.-smallModalWithDropdowns {
        .-body {
            @include media-down(m) {
                overflow: auto !important;
            }
        }

        .-bodyContent {
            @include media-down(l) {
                display: flex;
                justify-content: center;

                & > div {
                    flex: 0 0 auto;
                }
            }
        }
    }

    &.-withoutButtons {
        .-body {
            border-radius: 0 0 10px 10px;
            max-height: calc(100vh - 100px);

            @include media-down(l) {
                border-radius: 0;
            }
        }
    }

    &.-withoutTitle {
        .-body {
            border-radius: 10px 10px 0 0;
            max-height: 70vh;

            @include media-down(l) {
                border-radius: 0;
            }
        }
    }

    &.-formInForm {
        .m-modal__content ._wr {
            height: unset;
        }

        .-body {
            border-radius: 0;
        }
    }
}
