.m-pillItem {
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 15px;
        border: 1px solid $dark-blue-80;
        padding: 5px 10px;
        color: $dark-blue;
        word-break: break-word;
        overflow: hidden;
        animation-name: show;
        animation-duration: 0.3s;

        .label {
            margin-right: 5px;
            line-height: 16px;
        }

        @keyframes show {
            from {
                transform: scale(0);
            }

            to {
                transform: scale(1);
            }
        }

        .removeItemIcon {
            position: relative;
            top: unset;
            right: unset;
            width: 9px;
            height: 9px;
            min-width: 9px;
            min-height: 9px;
        }

        &.-withCustomItem {
            overflow: visible;
            padding: 5px 10px;

            .removeItemIcon {
                &::after,
                &::before {
                    //Note: Color is $dark-blue-40, but hex color code is used due to opacity and overlay of the 'x'
                    background-color: #a2a5b1 !important;
                }
            }
        }

        &.-withIconButton {
            overflow: visible;
        }

        .a-iconBtn {
            height: max-content;
        }
    }
}
