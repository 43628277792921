.m-signUpProfilePictureUploader {
    @include media-down(l) {
        height: 150px;
    }

    &--image,
    &--placeholder {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        min-width: 80%;
        max-width: 150px;
        height: 0;
        padding-top: 80%;
        border-radius: 50%;

        @include media-down(l) {
            height: 150px;
            width: 150px;
            min-width: 150px;
            padding-top: 0;
        }

        .-edit {
            position: absolute;
            top: unset;
            bottom: 5px;
            right: 5px;
            background: none;
            outline: inherit;
            border: none;
            padding: 0;
            cursor: pointer;
            transition: 0.3s ease-in-out;
        }

        .-placeholderImage {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &--signUpImage,
    &--signUpPlaceholder {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        min-width: 150px;
        height: 0;
        padding-top: 150px;
        border-radius: 50%;

        // @include media-down(l) {
        //     height: 150px;
        //     width: 150px;
        //     min-width: 150px;
        //     padding-top: 0;
        // }

        .-edit {
            position: absolute;
            top: unset;
            bottom: 5px;
            right: 5px;
            background: none;
            outline: inherit;
            border: none;
            padding: 0;
            cursor: pointer;
            transition: 0.3s ease-in-out;
        }

        .-signUpPlaceholderImage {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &--placeholder {
        z-index: 1;
        position: absolute;
        background-color: $dark-blue-20;
    }

    &--signUpPlaceholder {
        z-index: 3;
        position: absolute;
        background-color: #fafbfb;
        border: 1px solid $dark-blue-05;
    }

    &--image {
        z-index: 2;
        position: absolute;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $white;
        transition: 0.3s ease-in-out;
    }

    &--signUpImage {
        z-index: 2;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border: 1px solid $dark-blue-20;
        background-color: $white;
        transition: 0.3s ease-in-out;
    }

    .location {
        display: flex;
        align-items: center;

        &--locationName {
            margin-left: 5px;
        }
    }

    .-item {
        margin-left: 5px;
    }

    .-name {
        font-size: 20px;
        font-weight: 500px;
    }

    .-imageAndDataContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
