.a-stepCard {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    border: 1px solid $dark-blue-20;
    background-color: $white;
    padding: 20px;
    transition: 0.3s ease-in-out;

    &.-withHover {
        &:hover {
            border: 1px solid $dark-blue;
        }
    }

    &.-withCardImage {
        flex-direction: row;

        & > .column {
            width: calc(100% - 60px);
        }

        .a-stepCard__body {
            overflow: hidden;
        }
    }

    .-singleAction {
        display: flex;
        z-index: 2;

        svg {
            margin-right: 0 !important;
            cursor: pointer;
        }
    }

    .a-link {
        z-index: 2;
    }

    &__header {
        display: flex;
        align-items: center;
        width: 100%;

        @include media-down(m) {
            flex-direction: column-reverse;
            align-items: flex-start;
        }

        &.-headerSidebar {
            flex-direction: column-reverse;
            align-items: flex-start !important;

            .a-stepCard__header--left {
                width: 100%;

                .-textContent {
                    flex-wrap: nowrap;

                    .-title {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        word-break: break-word;
                        white-space: wrap;
                    }
                }
            }

            .a-stepCard__header--right {
                .-status {
                    flex-wrap: wrap;
                }
            }
        }

        &--left {
            overflow: hidden;

            .-textContent {
                width: 100%;
                overflow: hidden;
            }

            .-titleAndVerifiedIcon {
                max-width: 100%;
            }

            .-withCustomIcon {
                max-width: calc(100% - 40px);
            }

            .-title,
            .-subtitle {
                display: block !important;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &.-verifiedBadge {
                    width: calc(100% - 20px);
                }
            }

            .-fullTitle {
                white-space: wrap;
            }
        }

        &--right {
            @include media-down(m) {
                width: 100%;
                justify-content: space-between;
            }

            @include media-down(xs) {
                .-status {
                    flex-wrap: wrap;
                }
            }

            .-status {
                .-highlightedInfo {
                    padding: 0;
                }
            }

            // Mina TO DO: Change button with icon and without label to be IconButton
            .-action {
                .a-btn.-onlyIcon {
                    padding: 0;
                }
            }
        }

        .-avatarImage {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            min-width: 50px;
            min-height: 50px;
            border: 1px solid $dark-blue-20;
            border-radius: 100%;
            overflow: hidden;

            svg {
                position: absolute;
                margin-right: 0 !important;
            }

            img {
                position: absolute;
                object-fit: cover;
                background-color: $white;
                width: 100%;
                height: 100%;
            }
        }

        .-withAvatarImage {
            max-width: calc(100% - 60px);
        }

        .a-actions,
        .a-iconBtn {
            z-index: 3;
        }

        .a-iconBtn {
            .-tooltip {
                top: 3px;
                left: 35px;
                transform: none;
                white-space: nowrap;
                min-width: fit-content;
            }
        }

        &.-withOnlySingleAction {
            position: relative;
            padding-right: 40px;

            .a-stepCard__header--right {
                .-action {
                    position: absolute;
                    top: 0;
                    right: 10px;
                }
            }
        }

        &.-allowOverflow {
            .a-stepCard__header--left,
            .-textContent {
                overflow: visible;
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        width: 100%;
        transition: 0.2s ease-in-out;

        .-info {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
            padding: 5px 0;

            svg {
                min-width: 20px;
            }

            @include media-down(xs) {
                flex-wrap: wrap;
            }

            &.-column {
                flex-direction: column;
                align-items: flex-start;
            }

            &.-row {
                flex-direction: row;
            }

            &.-withColor {
                justify-content: flex-start;
            }

            &.-withIcon {
                gap: 5px;
            }

            .-values {
                display: flex;
                flex-wrap: wrap;
                gap: 5px;
                overflow: hidden;

                .-value.-valueWithColor.a-status {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .-textColor {
                &-orange {
                    color: $orange;
                }

                &-red {
                    color: $red;
                }

                &-green {
                    color: $green;
                }

                &-purple {
                    color: $purple;
                }

                &-blue {
                    color: $light-blue;
                }
            }

            .-valueWithColor {
                border-radius: 4px;
                padding: 3px 7px;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
            }

            .-valueWithoutColor {
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                word-break: break-word;
            }

            .-borderRadius {
                border-radius: 100px;
            }

            .-icon {
                display: flex;
                align-items: center;

                svg {
                    margin-right: 5px;
                }
            }

            &:not(.-column) {
                .-value {
                    max-width: 100%;
                    word-break: break-word;
                }
            }

            &.-withBorder {
                border-bottom: 1px dashed $dark-blue-20;
            }

            &.-withSpaceBetween {
                justify-content: space-between;
            }

            &.-boxWithSeparators {
                .-purple {
                    color: $purple-60;

                    .-darkerText {
                        color: $purple;
                    }
                }

                .-orange {
                    color: $orange-60;

                    .-darkerText {
                        color: $orange;
                    }
                }
            }

            &.-descriptionTextWithoutLimit {
                gap: 5px;

                .-value {
                    color: $dark-blue-60;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                }
            }

            &.-descriptionText {
                gap: 5px;
            }

            .-descriptionText {
                gap: 5px;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                word-break: break-word;
            }

            &.-onlyInOneLine {
                .-value {
                    max-width: calc(100% - 25px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &:last-child {
                padding-bottom: 0;
            }
        }

        .-infoFiles {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            padding: 5px 0;

            .-label {
                margin-right: 10px;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }

            .-wrap {
                display: flex;
                flex-wrap: wrap;

                .-values {
                    display: flex;
                    flex-wrap: wrap;

                    svg {
                        margin-right: 10px;
                    }

                    .-value {
                        display: flex;
                        align-items: flex-start;

                        &:not(:last-of-type) {
                            margin-right: 10px;
                        }

                        .-file {
                            min-width: 30px;
                            min-height: 20px;
                            max-height: 20px;

                            &Name {
                                word-break: break-word;
                            }
                        }
                    }
                }
            }

            &.-withSpaceBetween {
                justify-content: space-between;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }

        .-infoRow {
            display: flex;
            flex-wrap: wrap;
            padding: 5px 0;

            @include media-down(m) {
                flex-direction: column;
                gap: 0;
                padding: 0;
            }

            @include media-down(xs) {
                flex-direction: column;
            }

            .-info {
                &:last-of-type {
                    border: 0;

                    @include media-up(m) {
                        justify-content: flex-end;
                    }
                }

                @include media-up(m) {
                    padding: 0;
                    border: none;
                }
            }

            &.-withButtonsWrapper {
                justify-content: space-between;
                width: 100%;

                .a-btn {
                    z-index: 2;

                    &.-tertiary {
                        height: 20px !important;
                    }
                }

                .-columnButtons {
                    @include media-down(xs) {
                        flex-direction: column;
                        gap: 5px;
                        align-items: flex-start !important;
                    }
                }
            }

            &.-withColumns {
                @include media-down(m) {
                    flex-direction: row;
                    gap: 5px;
                }

                .-info {
                    max-width: 100%;
                    width: min-content;
                    flex-wrap: nowrap;

                    .-value {
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            &.-alignRight {
                justify-content: flex-end !important;
            }

            &:last-child {
                padding-bottom: 0;
            }

            &.-alwaysSeparated {
                flex-direction: row !important;
            }
        }

        .a-btn {
            z-index: 2;
        }

        .a-iconWithTooltip {
            z-index: 2;
        }

        .-label {
            @include media-up(l) {
                white-space: nowrap;
            }
        }

        .-divingModeExperience {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            margin-top: 10px;

            .-info {
                max-width: 100%;
                padding: 0;
            }
        }
    }

    &__footer {
        @include media-down(xs) {
            .a-btn {
                width: 100%;
            }
        }
    }

    .-expired,
    .-notExpired,
    .-restriction span:last-of-type,
    .-noRestriction span:last-of-type {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        padding: 3px 10px;
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 18px !important;
    }

    .-expired {
        background-color: $red-10;
        color: $red;
    }

    .-notExpired,
    .-noRestriction span:last-of-type {
        background-color: $green-10;
        color: $green;
    }

    .-restriction {
        span:last-of-type {
            background-color: $orange-10;
            color: $orange;
        }
    }

    .-restriction,
    .-noRestriction {
        justify-content: flex-start;

        span:last-of-type {
            margin-left: 10px;
        }
    }

    .a-btn.-tertiary {
        @include media-down(xs) {
            text-align: left;
        }
    }

    //NOTE MINA: This should be created as a separate class for pills
    &.-recordSummary {
        .-valueWithColor {
            border-radius: 100px;
        }

        .-label {
            color: $dark-blue-80;
        }
    }

    &.-noBorderCard {
        padding: 0;
        border: none;

        &:hover {
            border: none;
        }
    }

    .-cardContainerLink {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        cursor: pointer;
    }

    .-infoRowButtons {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px dashed $dark-blue-20;

        &.-twoButtons {
            justify-content: space-between;
            gap: 15px;

            @include media-down(s) {
                flex-direction: column;

                .a-btn {
                    margin-top: 0;
                }
            }
        }

        .a-btn {
            z-index: 2;
        }

        @include media-down(s) {
            .a-btn {
                width: 100%;
            }
        }
    }

    &.-sidebarCard {
        padding: 15px;

        .a-stepCard__head {
            flex-direction: column;
            gap: 10px;

            .-status {
                order: -1;
                justify-content: flex-start;
                flex-wrap: wrap;
            }
        }
    }

    &.-withOutBody {
        .a-stepCard__head {
            margin-bottom: 0;

            .-title {
                margin-bottom: 0;
            }
        }
    }

    .-singleSkill:not(:last-of-type) {
        border-bottom: 1px dashed $dark-blue-20;
    }

    .-listOfSkills {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .-skill {
            gap: 10px;
            margin-right: 5px;
            margin-top: 5px;

            .removeItemIcon {
                &::before,
                &::after {
                    background: $orange;
                }
            }
        }
    }

    .-infoGroup {
        &:not(:first-of-type) {
            border-top: 1px solid $dark-blue-20;
            margin-top: 20px;
            padding-top: 20px;
        }
        .-info {
            width: 100%;

            &:not(:first-of-type) {
                border-top: 1px dashed $dark-blue-20;
            }
        }
    }
}

@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        .-info .-valueWithoutColor {
            font-weight: 400;
        }
    }
}

@media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
    .-info .-valueWithoutColor {
        font-weight: 400;
    }
}

@supports (color: -webkit-link) {
    .-info .-valueWithoutColor {
        font-weight: 400;
    }
}

::-webkit-full-page-media,
:future,
:root {
    .-info .-valueWithoutColor {
        font-weight: 400;
    }
}
