@for $i from 0 through 500 {
    //.-mt10 => margin-top: 10px
    .-mt#{$i} {
        margin-top: #{$i}px;
    }

    //.-mb10 => margin-bottom: 10px
    .-mb#{$i} {
        margin-bottom: #{$i}px;
    }

    //MINA SCSS NOTE: This should be removed; it's better to use a gap instead of applying left or right margin.
    //.-ml10 => margin-left: 10px
    .-ml#{$i} {
        margin-left: #{$i}px;
    }

    //.-mr10 => margin-right: 10px
    .-mr#{$i} {
        margin-right: #{$i}px;
    }

    //.-pt10 => padding-top: 10px
    .-pt#{$i} {
        padding-top: #{$i}px;
    }

    //.-pb10 => padding-bottom: 10px
    .-pb#{$i} {
        padding-bottom: #{$i}px;
    }

    //.-h10 => height: 10px
    .-h#{$i} {
        height: #{$i}px !important;
    }

    //.-w10 => width: 10px
    .-w#{$i} {
        width: #{$i}px !important;
    }

    //.-h10 => min-height: 10px
    .-minh#{$i} {
        min-height: #{$i}px !important;
    }

    //.-w10 => min-width: 10px
    .-minw#{$i} {
        min-width: #{$i}px !important;
    }

    //.-z10 => z-index: 10
    .-z#{$i} {
        z-index: #{$i};
    }

    //.-gap10 => gap: 10px
    .-gap#{$i} {
        gap: #{$i}px;
    }

    //.-p10 => padding: 10px
    .-p#{$i} {
        padding: #{$i}px;
    }

    //.-borderRadius10 => border-radius: 10px
    .-borderRadius#{$i} {
        border-radius: #{$i}px;
    }
}
